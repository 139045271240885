.card-wizard{
    min-height: 410px;
    box-shadow: $box-shadow;
    opacity: 0;
    @include transition($general-transition-time, $transition-linear);

    &.active{
        opacity: 1;
    }

    .card-header {
      padding: 15px 0 !important;
      padding-bottom: 40px;
    }

    .nav-pills .nav-item .nav-link{
        padding: 12px;
    }

 /*    .label-step{
        display: inline-block;
        resize: horizontal;
    }
 */
    .nav-pills .nav-item .nav-link,
    .nav-pills .nav-item .nav-link.active,
    .nav-pills .nav-item .nav-link:hover,
    .nav-pills .nav-item .nav-link:focus,
    .nav-pills .nav-item .nav-link.active:focus,
    .nav-pills .nav-item .nav-link.active:hover{
        background-color: transparent;
        box-shadow: none;
    }

    .picture-container{
        position: relative;
        cursor: pointer;
        text-align: center;
    }

    .wizard-navigation{
      position: relative;
      .nav-link{
        display: inline-block;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
    }

    .wizard-navigation .nav-link i,
    .moving-tab i{
      display: inline-block;
      font-size: 19px;
      line-height: initial;
      margin-right: 6px;
      padding: 0;
      vertical-align: bottom;
    }

    .picture{
        width: 106px;
        height: 106px;
        background-color: #999999;
        border: 1px solid $light-gray;
        color: #FFFFFF;
        border-radius: 50%;
        margin: 5px auto;
        overflow: hidden;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;

        &:hover{
            border-color: #777b7e;
        }
        &.completed {
            &::after {
                background: #34bc9b;
            }
        }
    }

    .moving-tab{
        position: absolute;
        text-align: center;
        padding: 12px;
        font-size: 10px;
        text-transform: uppercase;
        -webkit-font-smoothing: subpixel-antialiased;
        top: 0px;
        left: 0px;
        background-color: $dark-gray;
        box-shadow: $box-shadow;
        color: $white-color !important;
        cursor: pointer;
        font-weight: 500;
        content: ""
    }
    
    .active-tab{
        text-align: center;
        text-transform: uppercase;
        background-color: $default-color;
        box-shadow: $box-shadow;
        color: $white-color !important;
        font-weight: 500;
        a{
            color: $white-color
        }
    }

    &[data-color="default"]{
        @include set-wizard-color($dark-gray)
    }
    &[data-color="primary"]{
        @include set-wizard-color($brand-primary);
    }
    &[data-color="white"]{
        @include set-wizard-color($white-color);

    }

    &[data-color="green"]{
        @include set-wizard-color($brand-success);
    }

    &[data-color="blue"]{
        @include set-wizard-color($brand-info);
    }

    &[data-color="orange"]{
        @include set-wizard-color($brand-warning);
    }

    &[data-color="red"]{
        @include set-wizard-color($brand-danger);
    }
    &[data-color="brown"]{
        @include sidebar-color($default-color);
    }
    &[data-active-color="brown"]{
        @include sidebar-color($default-color);
    }
    &[data-active-color="teal"]{
        @include set-wizard-color($teal-color);
        @include set-wizard-move-color-bg($teal-color-light)
    }
    &[data-active-color="midnight"]{
        @include set-wizard-color($midnight-color);
        // @include set-wizard-move-color-bg($midnight-color-light)
    }
    
    &[data-active-color="indigo"]{
        @include set-wizard-color($indigo-color);
        @include set-wizard-move-color-bg($indigo-color-light)
    }
    &[data-active-color="blueberry"]{
        @include set-wizard-color($blueberry-color);
        @include set-wizard-move-color-bg($blueberry-color-light)
    }
    &[data-active-color="periwinkle"]{
        @include set-wizard-color($periwinkle-color);
        @include set-wizard-move-color-bg($periwinkle-color-light)
    }
    &[data-active-color="peacock"]{
        @include set-wizard-color($peacock-color);
        @include set-wizard-move-color-bg($peacock-color-light)
    }
    &[data-active-color="sky"]{
        @include set-wizard-color($sky-color);
        @include set-wizard-move-color-bg($sky-color-light)
    }
    &[data-active-color="lavender"]{
        @include set-wizard-color($lavender-color);
        @include set-wizard-move-color-bg($lavender-color-light);
    }
    &[data-active-color="overcast"]{
        @include set-wizard-color($overcast-color);
        @include set-wizard-move-color-bg($overcast-color-light);
    }
    // &[data-color-btn="brown"]{
    //     @include set-wizard-color-btn($default-color)
    // }

    .picture input[type="file"] {
        cursor: pointer;
        display: block;
        height: 100%;
        left: 0;
        opacity: 0 !important;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .picture-src{
        width: 100%;
    }

    .tab-content{
        min-height: 355px;
        padding: 20px 0;
    }

    .wizard-footer{
        padding: 0 15px;

        .checkbox{
            margin-top: 16px;
        }
    }

    .disabled{
        display: none;
    }

    .wizard-header{
        text-align: center;
        padding: 25px 0 35px;

        h5{
           margin: 5px 0 0;
       }
    }

    .nav-pills > li{
        text-align: center;
    }

    .btn{
        text-transform: uppercase;
    }

    .info-text{
        text-align: center;
        font-weight: 300;
        margin: 10px 0 30px;
    }

    .choice{
        text-align: center;
        cursor: pointer;
        margin-top: 20px;

        &[disabled]{
            pointer-events: none;
            cursor: not-allowed;
            opacity: .5;
        }

        .icon{
            text-align: center;
            vertical-align: middle;
            height: 116px;
            width: 116px;
            border-radius: 50%;
            color: $default-color;
            margin: 0 auto 20px;
            border: 1px solid $light-gray;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
        }

        i{
            font-size: 30px;
            line-height: 116px;
            position: absolute;
            left: 0;
            right: 0;
        }

        &:hover,
        &.active{
            .icon{
                border-color: #2ca8ff;
            }
        }

        input[type="radio"],
        input[type="checkbox"]{
            position: absolute;
            left: -10000px;
            z-index: -1;
        }
    }

    .btn-finish{
        display: none;
    }

    .card-title + .description{
        font-size: $font-size-base + 3;
        margin-bottom: 32px;
    }

    .wizard-title{
        margin: 0;
    }

    .nav-item .nav-link .round-tab{
        width: 25px;
        height: 25px;
        line-height: 22px;
        display: inline-block;
        border-radius: 25px;
        background: #fff;
        border: 2px solid $default-color;
        color: $default-color;
        text-align: center;
        font-size: 14px;
        z-index: 22;
        position: relative;
    }
    
    .nav-item .nav-link .label-step{
        display: inline;
        font-size: 10px;
        /* white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        resize: horizontal; */
    }
    
    .line-step{
        background-color: #000;
        width:100%;
        height: 30px;
    }

    .nav-pills{
       /*  background-color: $dark-gray; */
        // background-color: $white-color;
        
        
        > li + li {
            margin-left: 0;
        }

        > li > a {
            border: 0 !important;
            border-radius: 0 ;
            line-height: 18px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            min-width: 100px;
            text-align: center;
            color: rgba(0, 0, 0, 0.664) !important;
            display: block;
            &::after {
                width: 100%;
                content: '';
                position: absolute;
                background: #f1f1f1;
                display: block;
                height: 5px;
                top: 22px;
                left: 0%;
                z-index: 1;
            }
        }
        > li.active > a,
        > li.active > a:hover,
        > li.active > a:focus,
        > li > a:hover,
        > li > a:focus{
            background-color: inherit;
            box-shadow: none;
        }

        > li i{
            display: block;
            font-size: 30px;
            padding: 15px 0;
        }
    }

  .form-group.select-wizard {
    margin-top: 15px;
  }

  .dropdown.show,
  .dropup.show {
    .dropdown-menu {
      @include transform-translate-y(0px);
    }
  }
}
