@mixin set-wizard-color($color) {
    .moving-tab{
        color: $color;
    }

    .picture{
        &:hover{
            border-color: $color;
        }
    }

    .choice{
        &:hover,
        &.active{
            .icon{
                border-color: $color;
                color: $color;
            }
        }
    }

    .active-tab{
        text-align: center;
        text-transform: uppercase;
        background-color: $color;
        box-shadow: $color;
        color: $color !important;
        font-weight: 500;
        a{
            color: $color
        }
    }

    // .form-group{
    //     .form-control{
    //         background-image: linear-gradient($color, $color), linear-gradient($mdb-input-underline-color, $mdb-input-underline-color);
    //     }
    // }

    .checkbox input[type=checkbox]:checked + .checkbox-material{
        .check{
            background-color: $color;
        }
    }

    .radio input[type=radio]:checked ~ .check {
        background-color: $color;
    }

    .radio input[type=radio]:checked ~ .circle {
        border-color: $color;
    }
    .card-footer{
        .btn-next{
            background-color: $color;
            &:focus,
            &:active,
            &.active,
            &:active:focus,
            &:active:hover,
            &.active:focus,
            &.active:hover{
                background-color: $color !important;
            }
            &.active:focus,
            &.active:hover{
                background-color: $default-color;
            }
            &:not([data-action]):hover{
                box-shadow:  none;
            }
        } 
        .btn-previous{
            background-color: $color;
            &:focus,
            &:active,
            &.active,
            &:active:focus,
            &:active:hover,
            &.active:focus{
                background-color: $color !important;
            }
            &.active:focus,
            &.active:hover{
                background-color: $default-color;
            }
            &:not([data-action]):hover{
                box-shadow:  none;
            }
        }
        .btn-finish{
            background-color: $color;
            &:focus,
            &:active,
            &.active,
            &:active:focus,
            &:active:hover,
            &.active:focus{
                background-color: $color !important;
            }
            &.active:focus,
            &.active:hover{
                background-color: $default-color;
            }
            &:not([data-action]):hover{
                box-shadow:  none;
            }
        }
    }

    .nav-item .nav-link .round-tab{
        width: 25px;
        height: 25px;
        line-height: 22px;
        display: inline-block;
        border-radius: 25px;
        background: #fff;
        border: 2px solid $color;
        color: $color;
        text-align: center;
        font-size: 14px;
        z-index: 22;
        position: relative;
    }
}

@mixin set-wizard-move-color-bg($color) {
    .moving-tab{
        background-color: $color;
        color: $white-color !important;
    }
}